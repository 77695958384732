<template>
  <div>
    <el-dialog
        title="订单退单"
        width="55%"
        :visible.sync="state"
        :modal-append-to-body="false"
        @close="close"
        center>
      <div>
        <el-form label-width="110px" :model="form" :rules="rules" ref="form">
          <el-row :gutter="20">
            <el-col :span="7">
              <el-form-item label="订单项目" prop="refundName">
                <el-select placeholder="请选择订单项目" v-model="form.refundName" style="width: 100%;">
                  <el-option
                      v-for="item in refundNameArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="订单号" prop="orderNo">
                <el-input v-model.number="form.orderNo" placeholder="请输入订单号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="退款日期" prop="createDate">
                <el-date-picker type="date" placeholder="退款日期"
                                v-model="form.createDate"
                                style="width: 100%;"
                                value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="7">
              <el-form-item label="退款人" prop="refundPeople">
                <el-select placeholder="请选择退款人" v-model="form.refundPeople" style="width: 100%;">
                  <el-option
                      v-for="item in refundPeopleArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="退款方式" prop="refundPayment">
                <el-select placeholder="请选择退款方式" v-model="form.refundPayment" style="width: 100%;" @change="choosePayment">
                  <el-option
                      v-for="item in refundPaymentArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="退款账号" prop="accountNumber">
                <el-input v-model.number="form.accountNumber" placeholder="请选择退款方式" readonly></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="7">
              <el-form-item label="退款目标账户" prop="refundTarget">
                <el-input v-model.number="form.refundTarget" placeholder="请输入退款账户"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="退款目标账号" prop="targetAccount">
                <el-input v-model.number="form.targetAccount" placeholder="请输入退款账号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="应退金额" prop="orderPrice">
                <el-input v-model.number="form.orderPrice" placeholder="请输入一个负数"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="7">
              <el-form-item label="实退金额" prop="refundAmount">
                <el-input v-model.number="form.refundAmount" placeholder="请输入一个负数"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-divider content-position="left">客户{{order.name}}的历史收款记录</el-divider>
        <el-row>
          <el-col :span="7" :offset="1">
            订单总价：{{order.orderPrice}}
          </el-col>
          <el-col :span="7" :offset="1">
            已收金额：{{order.spareMoney}}
          </el-col>
          <el-col :span="7" :offset="1">
            余额：{{order.orderSpare}}
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="addRefund">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "refund-add",
  inject: ['order'],
  created() {
    this.pageInit()
  },
  props: {
    refundAddState: {
      type: Boolean,
      default: false,
      required: true,
    }
  },
  data() {
    return {
      state: this.refundAddState,
      form: {
        refundName: "",
        orderNo: this.order.orderNo,
        createDate: new Date(),
        refundPeople: "",
        refundPayment: "",
        accountNumber: "",
        refundTarget: "",
        targetAccount: "",
        orderPrice: "",
        refundAmount: "",
      },
      rules: {
        refundName: [{required: true, message: "请选择订单项目", trigger: "change"}],
        orderNo: [{required: true, message: "请输入订单号", trigger: "blur"}],
        createDate: [{required: true, message: "请选择退款日期", trigger: "change"}],
        refundPeople: [{required: true, message: "请选择退款人", trigger: "change"}],
        refundPayment: [{required: true, message: "请选择退款方式", trigger: "change"}],
        accountNumber: [{required: true, message: "请选择退款方式", trigger: "blur"}],
        refundTarget: [{required: true, message: "请输入退款目标账户", trigger: "blur"}],
        targetAccount: [{required: true, message: "请输入退款目标账号", trigger: "blur"}],
        orderPrice: [{required: true, message: "请输入应退金额", trigger: "blur"},
                      { type: 'number', message: '必须为数字'}],
        refundAmount: [{required: true, message: "请输入实退金额", trigger: "blur"},
                      { type: 'number', message: '必须为数字'}],
      },
      refundNameArray: [],
      refundPeopleArray: [],
      refundPaymentArray: [],
    }
  },
  methods: {
    pageInit() {
      this.$selectUtils.queryProjectsIds("退款项目").then(response => {
        this.refundNameArray = JSON.parse(response.data.data).filter(item => item.name === '退单')
        this.form.refundName = this.refundNameArray[0].value
      })
      this.$selectUtils.refundPeopleIds().then(response =>{
        this.refundPeopleArray = JSON.parse(response.data.data)
      })
      this.$selectUtils.refundPaymentIds().then(response => {
        this.refundPaymentArray = JSON.parse(response.data.data)
      })

    },
    close() {
      this.$emit("close");
    },
    choosePayment() {
      this.$axios({
        method: 'GET',
        url: "/refundPayment/queryById",
        params: {
          id: this.form.refundPayment
        }
      }).then(response => {
        this.form.accountNumber = response.data.data.accountNumber
      })
    },
    addRefund() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$alert('确认要退单吗？', "退单", {
            dangerouslyUseHTMLString: true,
            type: "warning",
            showCancelButton: true
          }).then(()=>{
            this.$axios({
              method: "PUT",
              url: "/refund/refundChargeback/",
              data: {
                ...this.form,
                tenantCrop: localStorage.getItem("tenantCrop"),
                cusId: this.order.cusId,
                orderId: this.order.id,
              }
            }).then(response => {
              let flag = response.data.code === 200
              this.$message({
                showClose: true,
                message: flag ? '退单成功': response.data.msg,
                type: flag ? 'success':'error',
                duration: 1000,
              });
              if (flag) {
                this.close();
              }
            })
          })
        }
      })
    },
  },
}
</script>

<style scoped>

</style>